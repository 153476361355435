import { Card } from 'react-bootstrap';
import React from 'react';
import MyHelm from '../components/myhelm'
import Layout from '../components/layout'

export default function Gallery(props) {
    //const [index, setIndex] = useState(0);

    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };
    
    let title = "Gallery"
    let description = "Custom Websites and Web Apps designed by Telafast. Tell your friends, Telafast! Websites and Web Apps for individuals and small businesses"
    
    
    return (
        <Layout>
            <div className="container-fluid bg-dshade mt-3 pt-3" style={{minHeight: "65vh"}}>
                <MyHelm title={title} description={description} />
                {/* <Carousel id="website-carousel" 
                            className="carousel-dark text-center w-50" 
                            activeIndex={index} 
                            fade={true} 
                            interval={null} 
                            onSelect={handleSelect}
                            nextLabel={""}
                            prevLabel={""}>
                    <Carousel.Item>
                        <Image src="../images/heritage.png" width="100%" height="100%" />
                    </Carousel.Item>
                </Carousel> */}
                <h1 className="display-4 text-lshade text-center">Gallery</h1>
                <div className="row">
                    <div className="col-md d-flex justify-content-center">
                        <Card style={{height: "53vh", maxWidth: "53vw", alignSelf: "center"}} className="hvr-grow my-3 border-brand border-2">
                            <Card.Img  style={{minHeight: "50%"}}
                                       className="border-bottom border-2 border-brand"  variant="top" src={require("../images/heritage.png")} />
                            <Card.Body className="bg-lshade">
                                <Card.Title>
                                    <a href="https://heritagevillagenursingcenter.com"
                                        className="text-decoration-none text-daccent stretched-link"
                                    >
                                        Heritage Village Nursing Center
                                    </a>
                                </Card.Title>
                                <Card.Text className="text-dshade">
                                    Designed to specification. <br /> <br />
                                    Sister home of Colonial Park Manor, and Henreytta Community Skilled Healthcare & Rehab.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md d-flex justify-content-center">
                        <Card style={{height: "53vh", maxWidth: "53vw", alignSelf: "center"}} className="hvr-grow my-3 border-brand border-2">
                            <Card.Img  style={{minHeight: "50%"}}
                                       className="border-bottom border-2 border-brand"  variant="top" src={require("../images/colonial.png")} />
                            <Card.Body
                                       className="bg-lshade">
                                <Card.Title>
                                    <a href="https://colonialparkmanor.com"
                                        className="text-decoration-none text-daccent stretched-link"
                                    >
                                        Colonial Park Manor
                                    </a>
                                </Card.Title>
                                <Card.Text className="text-dshade">
                                    Designed to specification. <br /> <br />
                                    Sister home of Heritage Village and Henryetta Community Skilled Healthcare & Rehab.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md d-flex justify-content-center">
                        <Card style={{height: "53vh", maxWidth: "53vw", alignSelf: "center"}} className="hvr-grow my-3 border-brand border-2">
                            <Card.Img  style={{minHeight: "50%"}}
                                       className="border-bottom border-2 border-brand" variant="top" src={require("../images/henryetta.png")} />
                            <Card.Body className="bg-lshade">
                                <Card.Title>
                                    <a href="https://henryettacommunityskilled.com"
                                        className="text-decoration-none text-daccent stretched-link"
                                    >
                                        Henryetta Community Skilled Healthcare & Rehab
                                    </a>
                                </Card.Title>
                                <Card.Text className="text-dshade">
                                    Under redesign to match Heritage Village and Colonial Park Manor <br /> <br /> <br /> <br />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </Layout>
    )
}